import * as React from 'react';
import {useCallback, useState} from "react";
import {Login, InputField, ErrorInfo} from '../types/';
import * as API from '../utils/api'
import {InputCard} from "../molecules/InputCard";
import {debounce} from "@mui/material";
import {getFieldByPropName} from "../utils/utils";
import {ErrorFeedBack} from "../molecules/ErrorFeedback";


interface LoginCardProps {
    onLogin: () => void
}

const LoginCard: React.FC<LoginCardProps> = ({onLogin}) => {

    const [error, setError] = useState<ErrorInfo>()

    const getInputField = ():InputField<string>[] => ([
        { type: 'text', propName: 'userName', label: 'メールアドレス', value: '', required: true},
        { type: 'password', propName: 'password', label: 'パスワード', value: '', actionOnEnter: true, required: true},
    ])

    const setInputFieldToLogin = (login: Login, fields:InputField<string>[]) => {
        login.userName = getFieldByPropName(fields, 'userName')?.value || '';
        login.password = getFieldByPropName(fields, 'password')?.value || '';
    }

    const onAction = debounce(useCallback( async (fields: InputField<string>[]) => {

        const login : Login = {
            userName : '',
            password : ''
        }
        setInputFieldToLogin(login, fields);
        await API.post<{ error: string }>(`/login`, login).then((data) => {
            if (data?.error !== undefined) {
                setError({
                    message: 'ログインできませんでした。認証情報をお確かめください。',
                    timestamp: new Date().getTime(),
                })
            } else {
                onLogin()
            }
        })
    }, [setError]))

    return (
        <React.Fragment>
        <InputCard
            id={'owner-detail'}
            title={'ログインフォーム'}
            fields={getInputField()}
            onAction={onAction}
            actionLabel={'ログイン'}
            heightFixed={false}
            noDialog={true}
        />
            <ErrorFeedBack feedbackText={error?.message||''} savedTimestamp={error?.timestamp||0} />
        </React.Fragment>
    );
}

export default LoginCard;