import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {useCallback, useEffect, useState} from "react";
import {useRouter} from "next/router";
import LoginCard from "../organisms/LoginCard";
import * as API from "../utils/api";
export default function Home() {
    const router = useRouter();

    const [loaded, setLoaded] = useState(false)
    useEffect(()=>{
        API.post<{ success: boolean }>('/hello', {}, {}, false).then((data) => {
            if (!!data && data.success) {
                return router.push('/dashboard')
            } else {
                setLoaded(true)
            }
        }).catch(e => { console.error(e) })
    }, [setLoaded])

    const handleLogin = useCallback(() => {
        router.push('/dashboard').catch(e => { console.error(e) })
    }, [router])

  return (
      <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
          {loaded && (
          <Grid container spacing={3}>
              <Grid item xs={12} onClick={()=>handleLogin()}>
                  気軽に学べるNew Relicの学習サイト
              </Grid>
              <Grid item xs={12}>
                  <LoginCard onLogin={()=>handleLogin()} />
              </Grid>
          </Grid>
          )}
      </Container>
  )
}
